$toggler-size: 1.5em;


.navbar-toggler {
  width: $toggler-size;
  height: $toggler-size;
  padding: 0.25em !important;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0) !important;

  .line {
    width: 100%;
    height: $toggler-size/8;
    border-radius: $border-radius;
    background-color: $primary;
    margin-top: 10%;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    transition: all 0.2s ease-in-out;

    .line {
      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        width: 100%;
      }

      &:nth-child(3) {
        width: 100%;
      }
    }
  }

  &[aria-expanded="true"] {
    .line {
      transition: all 0.2s ease-in-out;

      &:nth-child(1) {
        width: 100%;
        transform: translateY(150%) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        width: 100%;
      }

      &:nth-child(3) {
        width: 100%;
        transform: translateY(-150%) rotate(-45deg);
      }
    }
  }
}