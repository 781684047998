/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: $border-radius;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: $border-radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
