// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from /sass/bootstrap/_variables.scss, paste it here and edit the value.
// As example you find one of the most important Bootstrap variable below: $brand-primary.
// Thats the ugly Bootstrap blue used for all the links, primary buttons etc.
// Just add your color code here, compile the theme.scss file to change this color everywhere



/*font family*/
$font-family-base: Open Sans;

$font-weight-normal: 400 !important;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$headings-font-weight: 300 !important;
$h1-font-size: $font-size-base * 3.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

h1, h2, h3 ,h4, h5 {
    text-transform: inherit !important;
}

/*Colors*/
$white: #F2F4F3;
$primary: #780201;
$secondary: #191D32;
$success: #A1AFB8;
$info: #A1AFB8;
$warning: #780201;
$danger: #7B3459;
$light: rgba(250, 250, 250, 0.7);
$dark: #07436B;

/*light gradient*/
$cr-light-gradient :linear-gradient(181.03deg, $secondary 1.72%, $primary 464.71%),
#C4C4C4;

.cr-light-gradient {
    background: $cr-light-gradient;
}

$body-bg: $white;
$body-color: $secondary;

/*border-style*/
$border-radius: 8px;

/*enable*/
$enable-gradients: true;
$enable-transitions: true;
$enable-shadows: true;
$enable-responsive-font-sizes: true;


/*
*
*Neumorphism Buttons*/

//Neumorphism variables
$neumorphism-blur: 12px; //12 blur intensitiy ideal
$neumorphism-distance: 5px; //distance
$neumorphism-insensity: 0.8; //max 1

//box shadow
$box-shadow-intensity: 10%;