/*images background sizes*/
.background-image-150px {
   background-size: 150px 150px !important;
}

.background-image-300px {
   background-size: 300px 300px !important;
}

.background-image-no-repeat {
   background-repeat: no-repeat !important;
}