/*Beschriftung einzelner Felder ausblenden*/
.nf-form-fields-required,
.nf-field-label {
    display:  none;
}

// Absenden Button Styles
#nf-field-4,
#nf-field-25 {
  all: unset;
  @extend .btn !optional;
  @extend .btn-primary !optional;
  @extend .p-2 !optional;
}
/*add this class to input fields to style*/
.nf_neumorph {
  @include secondary-neumorph-active;
  border-radius: $border-radius !important;
  @extend .p-2 !optional;
  @extend .m-1 !optional;
}

/*eroor styles*/
.nf-error .ninja-forms-field {
  border-color: $primary !important;
}
