#masthead {
  @extend .sticky-top;
}

.mysearchtoggle {
  display: none;
}

//simple style
.navbar {
  @extend .navbar-light;
  @extend .bg-light;
}

.navbar-nav {
  @extend .ml-auto;
}

/*brand-logo*/

@include media-breakpoint-up(md) {
  .navbar-brand {
    max-width: 12% !important;
    display: contents;

    img.img-fluid {
      width: 4em;
      margin-right: 1em;
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-brand {
    max-width: 50% !important;

    img.img-fluid {
      width: 4em;
    }
  }

}


@include media-breakpoint-down(md) {

  //nav menu animation
  #navbarNavDropdown {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 50vw;
    background: $body-bg;
    margin: 0 0 0 -50vw;
    transition: margin .4s ease-in-out .2s;
  }

  #navbarNavDropdown.show {
    margin: 0;
    transition: margin .4s ease-in-out .2s;
  }

  //menu size
  ul#main-menu {
    height: 100vh;
    padding: 40% 6% 6% 6%;
  }
}

// toggler
button.navbar-toggler {
  z-index: $zindex-tooltip;
}


//link color
a.nav-link {
  color: $primary !important;
  text-transform: uppercase;
}