@import "../sass/theme/local/local_child_theme_variables";


.portfolio-card .card {
  border: none !important;
  background-color: $secondary;
}

//give this class to a wordpress column
.neumorph-portfolio-card {
  @extend .card;
  @extend .secondary-neumorph;
  @extend .p-3;
  @extend .p-xl-5;
  @extend .m-3;
  @extend .m-xl-5;
  background-color: $secondary !important;
}

.card {
  border-color: transparent !important;
}

.card {
  height: 100%;
  width: 100%;
  border: 1px solid gray !important;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    
      margin-bottom: 1em !important;

  }

  .wp-block-image {
    margin: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
  }

  .wp-block-image img {
    width: 100% !important;
    height: auto;
  }

  p {
    @extend .mt-3;
    @extend .mb-2;
    @extend .mx-1;
    @extend .card-text;
  }
}

//gutenberg styles

.wp-block-group.card {
  padding: 0;
  margin: 0;
}

.wp-block-group.card.border-success {
  border: 1px solid gray !important;
}