.btn.secondary-neumorph {
    font-weight: 700;
    font-size: larger;
    @extend .my-4;
    @extend .px-4;
    @extend .py-3;
    @extend .py-3;
}


