//neumorph-designs

// get the color variables

// get the color variables for box shadow light
$neumorph-box-shadow-colors-light: ("primary": lighten($primary, $box-shadow-intensity),
  "secondary": lighten($secondary, $box-shadow-intensity),
  "success": lighten($success, $box-shadow-intensity),
  "info": lighten($info, $box-shadow-intensity),
  "warning": lighten($warning, $box-shadow-intensity),
  "danger": lighten($danger, $box-shadow-intensity),
  "light": lighten($light, $box-shadow-intensity),
  "dark": lighten($dark, $box-shadow-intensity),
);

// stylelint-disable-next-line scss/dollar-variable-default
$neumorph-box-shadow-colors-dark: ("primary": darken($primary, $box-shadow-intensity),
  "secondary": darken($secondary, $box-shadow-intensity),
  "success": darken($success, $box-shadow-intensity),
  "info": darken($info, $box-shadow-intensity),
  "warning": darken($warning, $box-shadow-intensity),
  "danger": darken($danger, $box-shadow-intensity),
  "light": darken($light, $box-shadow-intensity),
  "dark": darken($dark, $box-shadow-intensity),
);

//neumorph mixin
@mixin neumorph-variant($color) {
  border-color: transparent;
  outline: 0 none;
  background: $color !important;
  //transition: box-shadow 0.3s ease-in-out;
  box-shadow: $neumorphism-distance $neumorphism-distance $neumorphism-blur (map-get($neumorph-box-shadow-colors-dark, $color)),
    (-$neumorphism-distance) (-$neumorphism-distance) $neumorphism-blur (map-get($neumorph-box-shadow-colors-light, $color)) !important;
}

@mixin neumorph-variant-active($color) {
  border-color: transparent !important;
  background: $color !important;
  box-shadow: inset $neumorphism-distance $neumorphism-distance $neumorphism-blur (map-get($neumorph-box-shadow-colors-dark, $color)),
    inset (-$neumorphism-distance) (-$neumorphism-distance) $neumorphism-blur (map-get($neumorph-box-shadow-colors-light, $color)) !important;
}

//FALLBACK SECONDARY
@mixin secondary-neumorph {
  @include neumorph-variant($secondary);
}

@mixin secondary-neumorph-active {
  @include neumorph-variant-active($secondary);
}

// add variant to classes
@each $name,
$color in $theme-colors {

  .#{$name}-neumorph,
  .#{$name}-neumorph:visited,
  .#{$name}-neumorph:focus {
    @include neumorph-variant($name);
  }

  .#{$name}-neumorph:hover:not(.btn),
  .#{$name}-neumorph:hover:not(#wp-submit) {
    transform: translateX(0px) translateY(-20px) !important;
  }

  .#{$name}-neumorph-active {
    @include neumorph-variant-active($name);
    background: $color;
  }
}