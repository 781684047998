@import "theme/child_theme"; // <------- Add your styles into this file

/*--------------------------------------------------------------
## Login
--------------------------------------------------------------*/
body.login div#login h1 a {
    background-image: url(https://montageservice-mueller.de/wp-content/uploads/2019/03/cropped-Logo_MontageService-Länglich.png); //Add your own logo image in this url
    padding-bottom: 30px;
}

body.login {
    background-color: $secondary;
}

p#backtoblog {
    display: none;
}

#loginform {
    @extend .secondary-neumorph;
    background-color: $secondary;
    border-radius: $border-radius;
}

.login #backtoblog a,
.login #nav a {
    color: $primary;
}

// Submit  input button
.wp-core-ui .button-primary,
.wp-core-ui .button-primary:hover,
.wp-core-ui .button-primary:focus,
.wp-core-ui .button-primary:active {
    @extend .secondary-neumorph;
    width: 100%;
    transform: none !important;
    background-color: $secondary;
    color: $primary !important;
    text-transform: uppercase !important;
    margin-top: 2em;
    font-size: large;
}

//dashicons color
.dashicons {
    color: $primary !important;
}

// input
form,
input {
    //force yellow background in chrome
    //box-shadow: 0 0 0 100px rgba(250, 250, 250, 0) inset !important;
    background-color: $secondary !important;
    border: 1px solid $primary;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

.login #login_error {
    border-left-color: $primary;
}

input:focus,
textarea,
button:focus {
    border: none !important;
}

//color links

.login #backtoblog a,
.login #nav a {
    color: $primary;
}